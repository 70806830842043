/*
#***********************************************
#
#      Filename: src/api/insideOrder/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 在场订单管理
#        Create: 2021-11-16 17:15:39
# Last Modified: 2021-11-26 14:02:06
#***********************************************
*/
import request from '@/utils/request'
import Qs from 'qs'

// 批量删除
export function batchDelete(data) {
  return request({
    url: '/order/inParking/batchdel',
    method: 'post',
    params: data,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { indices: false })
    }
  })
}

// 单个删除
export function deleteItem(data) {
  return request({
    url: '/order/inParking/delete',
    method: 'post',
    params: data
  })
}

// 导出
export function exportRecord(data) {
  return request({
    url: '/order/inParking/export',
    method: 'get',
    params: data
  })
}

// 数据列表
export function list(data) {
  return request({
    url: '/order/inParking/list',
    method: 'get',
    params: data
  })
}
