<!-- @format -->

<!--
#***********************************************
#
#      Filename: src/views/Record/PresentVehicles.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 当前在场记录
#        Create: 2021-10-28 14:11:02
# Last Modified: 2021-11-30 09:40:42
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @refresh="handleRefresh"
      @batch-delete="handleBatchDelete"
      @export="handleExport" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.fromTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop">
        <template slot-scope="scope">
          <el-popover v-if="scope.row.cloudEnterPicture" placement="right" width="400" trigger="click">
            <i slot="reference" class="el-icon-picture-outline-round">点击显示</i>
            <el-image
              :src="scope.row.cloudEnterPicture + '?x-oss-process=image/resize,m_fill,w_900,h_500'"
              :preview-src-list="[scope.row.cloudEnterPicture]" />
          </el-popover>
          <i v-else class="el-icon-warning-outline">暂无图片</i>
        </template>
      </el-table-column>
      <!-- <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          {{ scope.row.toTime === 0 ? '-' : parseTime(scope.row.toTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          <el-popover v-if="scope.row.cloudExitPicture" placement="right" width="400" trigger="click">
            <i slot="reference" class="el-icon-picture-outline-round">点击显示</i>
            <el-image
              :src="scope.row.cloudExitPicture + '?x-oss-process=image/resize,m_fill,w_900,h_500'"
              :preview-src-list="[scope.row.cloudExitPicture]" />
          </el-popover>
          <i v-else class="el-icon-warning-outline">暂无图片</i>
        </template>
      </el-table-column> -->
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除车位', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import moment from 'moment'
  import { list, batchDelete, deleteItem, exportRecord } from '@/api/insideOrder' // eslint-disable-line
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'PresentVehicles',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          communityId: '',
          buildingNumber: '',
          areaNumber: '',
          floor: '',
          number: '',
          remark: '',
          unit: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          communityId: [{ required: true, message: '小区名称不能为空', trigger: 'blur' }],
          areaNumber: [{ required: true, message: '区号不能为空', trigger: 'blur' }],
          buildingNumber: [{ required: true, message: '楼号不能为空', trigger: 'blur' }],
          unit: [{ required: true, message: '单元号不能为空', trigger: 'blur' }],
          floor: [{ required: true, message: '楼层号不能为空', trigger: 'blur' }],
          number: [{ required: true, message: '房间号不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: false,
            searchPlaceholder: '车牌号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: [
              {
                label: '批量删除',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateTimeRange',
                name: 'dateRange'
              },
              {
                type: 'select',
                name: 'chargeType',
                placeholder: '收费类型',
                options: [
                  { label: '免费', value: 0 },
                  { label: '收费', value: 1 }
                ]
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '车牌号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carNumber'
          },
          {
            label: '进场时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'fromTime'
          },
          {
            label: '进场图片',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'cloudEnterPicture'
          },
          {
            label: '停车时长',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'totalTime'
          },
          {
            label: '收费类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'chargeTypeCN'
          },
          {
            label: '是否在场',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'statusCN'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          delete: 'persent-vehicles:delete',
          export: 'persent-vehicles:export'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.pId = this.pid
        queryData.chargeType = this.filterData.chargeType
        this.filterData.searchStr && (queryData.carNumber = this.filterData.searchStr)
        if (this.filterData.dateRange) {
          queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        }

        list(queryData)
          .then((res) => {
            if (res.result) {
              this.dataList = res.data.records
              this.total = res.data.total
            } else {
              this.noticeError(res.message || res.message || res.msg)
            }
          })
          .catch((err) => {
            console.error('在场车辆列表查询出错', err)
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        this.$confirm('是否删除所选数据？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.pId = this.pid
            queryData.id = id
            deleteItem(queryData)
              .then((res) => {
                this.handleRefresh()
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            console.log(err)
          })
      },

      /**
       * 批量删除
       */
      handleBatchDelete() {
        this.$confirm('是否批量删除所选数据？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            const idsArr = []
            this.selectItems.forEach((item) => {
              idsArr.push(item.id)
            })
            queryData.ids = idsArr
            queryData.pId = this.pid
            batchDelete(queryData)
              .then((res) => {
                this.handleRefresh()
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            console.log(err)
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出在场车辆列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.pId = _this.pid
            exportRecord(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'fromTime') {
                        return _this.parseTime(v[j])
                      } else if (j === 'toTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '在场车辆列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      }
    }
  }
</script>
